import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Container from "react-bootstrap/Container"

import { VerticalTimeline } from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"

import FadeIn from "react-fade-in"

import TimelineSection from "../components/history/timelineSection"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const History = ({ intl, data }) => {
  let strapiEdges = []
  let strapiNode
  switch (intl.locale) {
    case "en":
      strapiEdges = data.allStrapiEnHistory.edges
      strapiNode = data.allStrapiEnPage.edges[0].node
      break
    case "ko":
      strapiEdges = data.allStrapiKoHistory.edges
      strapiNode = data.allStrapiKoPage.edges[0].node
      break
    case "cn":
      strapiEdges = data.allStrapiCnHistory.edges
      strapiNode = data.allStrapiCnPage.edges[0].node
      break
    default:
      break
  }

  const sortFn = (a, b) => {
    return new Date(b.node.date).getTime() - new Date(a.node.date).getTime()
  }

  strapiEdges.sort(sortFn)

  let timeline = <></>
  if (strapiEdges.length) {
    timeline = strapiEdges.map(event => {
      if (event.node.thumbnail) {
        return (
          <TimelineSection
            key={event.node.strapiId}
            title={event.node.title}
            date={event.node.date}
            fluidImage={event.node.thumbnail.childImageSharp.fluid}
          />
        )
      } else {
        return (
          <TimelineSection
            key={event.node.strapiId}
            title={event.node.title}
            date={event.node.date}
          />
        )
      }
    })
  }

  return (
    <Layout page="history">
      <SEO lang={intl.locale} title={strapiNode.title} />
      <Container fluid>
        <FadeIn>
          <h1 className="text-center">{strapiNode.title}</h1>
          <p className="text-center">{strapiNode.subtitle}</p>
          <VerticalTimeline layout="1-column">{timeline}</VerticalTimeline>
        </FadeIn>
      </Container>
    </Layout>
  )
}

export default injectIntl(History)

export const query = graphql`
  query {
    allStrapiKoHistory {
      edges {
        node {
          strapiId
          title
          date
          thumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allStrapiEnHistory {
      edges {
        node {
          strapiId
          title
          date
          thumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allStrapiCnHistory {
      edges {
        node {
          strapiId
          title
          date
          thumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allStrapiKoPage(filter: { url: { eq: "/history" } }) {
      edges {
        node {
          url
          title
          subtitle
        }
      }
    }
    allStrapiEnPage(filter: { url: { eq: "/history" } }) {
      edges {
        node {
          url
          title
          subtitle
        }
      }
    }
    allStrapiCnPage(filter: { url: { eq: "/history" } }) {
      edges {
        node {
          url
          title
          subtitle
        }
      }
    }
  }
`
