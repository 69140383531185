/* eslint-disable array-callback-return */
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { VerticalTimelineElement } from "react-vertical-timeline-component"

import { MdLocationOn } from "react-icons/md"

const TimelineSection = ({ intl, title, fluidImage, date }) => {
  let imgComp = <></>
  if (fluidImage) {
    imgComp = <Img alt={title} fluid={fluidImage} />
  }
  return (
    <VerticalTimelineElement icon={<MdLocationOn />}>
      <Row>
        <Col xs={12} lg={6} className="vertical-image-wrapper">
          {imgComp}
        </Col>
        <Col xs={12} lg={6} className="vertical-text-wrapper my-auto">
          <h1>{title}</h1>
          <h2>{date}</h2>
        </Col>
      </Row>
    </VerticalTimelineElement>
  )
}

export default injectIntl(TimelineSection)
